import React, {useState}  from 'react';
import styled from 'styled-components';

const Field = styled.div`
    width: 100%;
`

function SearchBar({onSearchChange, searchError}) {
    const [value, setValue] = useState('');

    return (
        <div className="panel-block">
            <Field className="field">
            <div className="control has-icons-right">
                <input  className={`input is-rounded ${searchError ? 'is-danger' : ''}`} value={value} onChange={e => {
                    const searchText = e.target.value
                    setValue(searchText)
                    onSearchChange(searchText)
                }} type="text" placeholder="Search (title, author, keywords)"/>
                <span className="icon is-small is-right" onClick={
                    () => {
                        setValue('')
                        onSearchChange('')
                    }
                }>
                <i className="delete is-small"/>
                </span>
            </div>
            </Field>
        </div>
    )
};

export default SearchBar;