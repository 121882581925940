import {useCookies} from "react-cookie";

export const Message = ({content}) => {
    const [cookies, setCookie] = useCookies(['information-closed']);

    const handleClose = () => {
        setCookie('information-closed', true, { path: '/' });
    }

    if(cookies['information-closed']) return null;

    return (
        <article className="message is-info" style={{margin: "0 10px 0 10px"}}>
            <div className="message-header">
                <p>Important Information</p>
                <button className="delete" aria-label="delete" onClick={() => handleClose()}></button>
            </div>
            <div className="message-body">
                {content}
            </div>
        </article>
    )
}