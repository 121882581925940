import React from 'react'
import ParallelSessions from "./ParallelSessions";
import {isEmpty} from 'lodash'

function ScheduleTabsContent(props) {
    const tracksByDay = props.data.schedule[props.activeDay.toUpperCase()]

    let daySchedule = []
    // eslint-disable-next-line no-unused-vars
    for(let track of tracksByDay) {
        const start = new Date(track.start)
        const finish = new Date(track.end)

        // Find presentations which fits time ranges
        const presentations = props.data.presentations.filter(pres => {
            const date = new Date(pres.date)
            return date >= start && date <= finish
        })

        // Group presentations by track
        const grp = presentations.reduce((result, item) => {
            result[item.session] = [...result[item.session] || [], item];
            return result
        }, {})

        daySchedule.push(grp)
    }

    const namesAndLocations = props.data.sessions
    const rooms = props.data.rooms

    let panels = []
    for(let i = 0; i<tracksByDay.length; ++i){
        if(isEmpty(daySchedule[i])) continue

        panels.push(
            <div key={tracksByDay[i].start} className="panel-block">
                <ParallelSessions track={tracksByDay[i]} sessions={daySchedule[i]} rooms={rooms} namesAndLocations={namesAndLocations}/>
            </div>
        )
    }

    return (
        <>
            {panels}
        </>
    )
}

export default ScheduleTabsContent
